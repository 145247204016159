@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    color: #172A4C;
    font-family: Raleway;
    overflow-x: hidden;

}

.text-left {
    max-width: 94%;
    overflow-wrap: break-word;
}

.swiper-slide:nth-child(2n) {
    width: 44rem;
}

.my-custom-input-style {
    /* Add your custom styles here */
    border: 1px solid #ccc;
    borderRadius: 5px;
    padding: 10px;
    fontSize: 16px;
    /* Add any other styles as needed */
}


.App-header {
    display: flex;

    font-size: calc(10px + 1.5vmin);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.fc-scrollgrid-section.fc-scrollgrid-section-body {
    overflow-x: auto;
    overflow-y: hidden;
}


.rbc-day-bg {
    transition: background-color 0.15s ease-in-out;
    height: 100%;
    position: absolute;
    top: 0%;
    right: 0%;
    width: 100%;
    background: transparent;
    cursor: pointer;
    color: black;
}

.rbc-today {
    background-color: transparent !important;
    cursor: pointer;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable-month-event {
    background-color: rgba(255, 165, 0, 0.5); /* Change this to your desired color */
}
.text-link {
    color: #2563eb;
    text-decoration: underline;
}
.text-link:hover {
    color: #1e40af;
}
.react-datepicker-wrapper {
    width: 100%;
}

/* Add these styles */
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Add to your existing styles */
.snap-x {
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
}

.snap-center {
    scroll-snap-align: center;
}

/* Optional: Add smooth scrolling behavior */
@media (prefers-reduced-motion: no-preference) {
    .scroll-smooth {
        scroll-behavior: smooth;
    }
}

/* React Quill Editor Styles */
.quill {
    background-color: white;
    border-radius: 0.5rem;
    border: 2px solid #e5e7eb;
}

.ql-container {
    min-height: 200px;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.ql-toolbar {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: #f9fafb;
}

.ql-editor {
    min-height: 200px;
    font-size: 1rem;
    line-height: 1.5;
}

.ql-editor.ql-blank::before {
    color: #9ca3af;
    font-style: normal;
}

/* Quill Content Display Styles */
.quill-content {
    font-size: 1rem;
    line-height: 1.5;
}

.quill-content h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
}

.quill-content p {
    margin-bottom: 0.5rem;
}

.quill-content ul, .quill-content ol {
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
}

.quill-content ul {
    list-style-type: disc;
}

.quill-content ol {
    list-style-type: decimal;
}

.quill-content a {
    color: #0066cc;
    text-decoration: underline;
}

.quill-content strong {
    font-weight: bold;
}

.quill-content em {
    font-style: italic;
}

.quill-content .ql-align-center {
    text-align: center;
}

.quill-content .ql-align-right {
    text-align: right;
}

.quill-content .ql-align-justify {
    text-align: justify;
}