.btn {
    border-radius: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: #ffff;
    cursor: pointer;
    transition: all 450ms ease;
}

.Disbtn {
    border-radius: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;


}

.sparkle {
    fill: #FF5A5F;
    transition: all 450ms ease;
}

.text {
    font-weight: 600;
    color: #FF5A5F;
}

.btn:hover {
    background: linear-gradient(0deg, #FF5A5F, #fe8e92);
    box-shadow: inset 0px 1px 0px 0px rgba(255, 255, 255, 0.4),
    inset 0px -4px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 4px rgba(255, 255, 255, 0.2),
    0px 0px 10px 0px #FF5A5F;
    transform: translateY(-2px);
}

.btn:hover .text {
    color: white;
}

.btn:hover .sparkle {
    fill: white;
    transform: scale(1.2);
}